<template>
  <b-container id="object-page" fluid="md">
    <h1>
      {{ title }}
    </h1>
    <base-detail-modals
      v-model="modals"
      @delete-entity="deleteEntity"
      @delete-draft="deleteDraft"
      @publish="publish"
      @cancel-leave="leaveGuard.modalActive = false"
      @perform-leave="$router.push(leaveGuard.to)"
    />
    <b-form ref="form" :validated="wasValidated">
      <div id="form-block" role="tablist">
        <collapsible-card
          :visible="false"
          :header="$t('pages.inscriptions.signum')"
        >
          <b-row>
            <b-col>
              <label for="form-element-signumInscriptions">
                {{ $t("pages.inscriptions.signa") }}
                {{ $t("general.required") }}
              </label>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <signum-inscriptions-component
                v-if="draft.signumInscriptions"
                id="form-element-signumInscriptions"
                v-model="draft.signumInscriptions"
                @change="changed"
              />
            </b-col>
          </b-row>
        </collapsible-card>
        <collapsible-card
          :visible="false"
          :header="$t('pages.inscriptions.physicalObject')"
        >
          <inscription-object
            v-if="draft.physicalObject"
            id="form-element-physicalObject"
            v-model="draft.physicalObject"
            @change="changed"
          />
        </collapsible-card>
        <collapsible-card :header="$t('pages.inscriptions.translation')">
          <texts-component v-model="draft" @change="changed" />
        </collapsible-card>
        <collapsible-card
          :visible="false"
          :header="$t('pages.inscriptions.period')"
        >
          <b-row>
            <b-col>
              <label for="form-element-period"
                >{{ $t("pages.inscriptions.period") }}
                {{ $t("general.required") }}</label
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="auto">
              {{ $t("pages.inscriptions.periodCertainty") }}
            </b-col>
            <b-col sm="auto">
              <b-form-checkbox
                v-model="draft.periodCertainty"
                name="periodCertainty-checkbox"
                switch
                @change="changed"
              >
                {{
                  draft.periodCertainty
                    ? $t("pages.inscriptions.periodCertain")
                    : $t("pages.inscriptions.periodUncertain")
                }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <multiselect
                v-if="draft.period && periods.length > 0"
                id="form-element-period"
                v-model="draft.period.id"
                :options="periods.map(i => i.id)"
                :custom-label="periodLabel"
                :show-labels="false"
                @select="changed"
              />
            </b-col>
          </b-row>
        </collapsible-card>
        <collapsible-card
          :visible="false"
          :header="$t('pages.inscriptions.runeTypes')"
        >
          <label for="form-element-runetypes">
            {{ $t("pages.inscriptions.runeTypes") }}
          </label>
          <b-row>
            <b-col>
              <runetypes-selector
                v-if="draft.runeTypes"
                id="form-element-runetypes"
                v-model="draft.runeTypes"
                @change="changed"
              />
            </b-col>
          </b-row>
          <hr />
          <label for="form-element-runetypeDescription">
            {{ $t("pages.inscriptions.runetypeDescription") }}
          </label>
          <b-row v-if="draft.runetypeDescription != null">
            <b-col>
              <b-form-input
                id="form-element-runetypeDescription"
                v-model="draft.runetypeDescription"
                @change="changed"
                @input="changed"
              />
            </b-col>
            <b-col sm="auto">
              <multiselect
                v-if="modernLanguages.length > 0"
                v-model="draft.runetypeDescriptionLang.id"
                :options="modernLanguages.map(i => i.id)"
                :custom-label="key => modernLanguages.find(x => x.id == key).sv"
                :show-labels="false"
                @select="changed"
              />
            </b-col>
          </b-row>
          <b-row v-if="draft.runetypeDescription == null">
            <b-col>
              <b-button
                v-t="'pages.inscriptions.addrunetypeDescription'"
                @click="addRunetypeDescription"
              />
            </b-col>
          </b-row>
        </collapsible-card>
        <collapsible-card
          :visible="false"
          :header="$t('pages.inscriptions.carver')"
        >
          <carver-inscriptions
            v-if="draft.carverInscriptions"
            id="form-element-carverInscriptions"
            v-model="draft.carverInscriptions"
            @change="changed"
          />
        </collapsible-card>
        <collapsible-card
          :visible="false"
          :header="$t('components.references.header')"
        >
          <references-component
            v-if="draft.references"
            id="form-element-references"
            v-model="draft.references"
            @change="changed"
          />
        </collapsible-card>
      </div>
      <hr />
      <b-form-group
        :label="$t('components.baseDetail.commentLabel')"
        label-for="draft-comment"
      >
        <b-form-textarea
          id="draft-comment"
          :key="'comment-' + commentKey"
          v-model="draftDetails.comment"
          :disabled="!edited && !isDraft"
          :placeholder="$t('components.baseDetail.commentPlaceHolder')"
          class="comment-area"
          rows="3"
          max-rows="15"
          @input="setEdited(true)"
        />
      </b-form-group>
      <b-form-group
        :label="$t('components.baseDetail.publicCommentLabel')"
        label-for="draft-public-comment"
      >
        <ckeditor
          v-if="edited || isDraft"
          v-model="draftDetails.publicComment"
          :editor="publicCommentEditor"
          :config="publicCommentEditorConfig"
          class="public-comment-area"
          @input="setEdited(true)"
        />
        <!-- Use class form-control and the readonly attribute to emulate a disabled textarea
              and get the same grayed-out look as the comment field. -->
        <div v-else class="form-control comment-disabled" readonly>
          {{
            draftDetails.publicComment || publicCommentEditorConfig.placeholder
          }}
        </div>
      </b-form-group>

      <b-form-row v-if="!edited && !isDraft" class="form-group">
        <b-col>
          <p v-t="'components.baseDetail.disabledCommentsExplanation'" />
        </b-col>
      </b-form-row>

      <b-form-row v-if="draft.period && draft.period.id == null">
        <b-col>
          <p v-t="'pages.inscriptions.disabledDueToMissingPeriod'" />
        </b-col>
      </b-form-row>

      <b-form-row
        v-if="draft.physicalObject && draft.physicalObject.id == null"
      >
        <b-col>
          <p v-t="'pages.inscriptions.disabledDueToMissingObject'" />
        </b-col>
      </b-form-row>

      <b-form-row class="form-group form-group-buttons">
        <b-col lg="auto" md="12">
          <b-button
            key="button-save"
            v-t="'general.save'"
            :disabled="!edited"
            block
            class="mb-2"
            variant="primary"
            @click="save"
          />
        </b-col>
        <b-col lg="auto" md="12">
          <b-button
            key="button-reset"
            v-t="'general.reset'"
            :disabled="!edited"
            block
            class="mb-2"
            variant="outline-primary"
            @click="reset"
          />
        </b-col>
        <b-col v-if="!isDraft && !isNew" lg="auto" md="12">
          <b-button
            key="button-delete"
            v-t="localeSection + '.delete'"
            v-b-modal
            block
            class="mb-2"
            variant="outline-primary"
            @click="showModal('deleteEntity')"
          />
        </b-col>
        <b-col v-if="isDraft" lg="auto" md="12">
          <b-button
            key="button-delete-draft"
            v-t="'general.deleteDraft'"
            v-b-modal
            block
            class="mb-2"
            variant="outline-primary"
            @click="showModal('deleteDraft')"
          />
        </b-col>

        <b-col v-if="isDraft" lg="auto" md="12">
          <b-button
            key="button-publish"
            v-t="'general.publish'"
            v-b-modal
            block
            class="mb-2"
            variant="outline-primary"
            @click="showModal('publishDraft')"
          />
        </b-col>
      </b-form-row>
    </b-form>
  </b-container>
</template>

<script>
import gql from "graphql-tag";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LeaveGuard from "@/mixins/LeaveGuard";

export default {
  name: "InscriptionPage",
  components: {
    CollapsibleCard: () => import("@/components/CollapsibleCard"),
    BaseDetailModals: () =>
      import("@/components/BaseDetailSimple/BaseDetailModals"),
    SignumInscriptionsComponent: () =>
      import("@/components/SignumInscriptionsComponent"),
    RunetypesSelector: () => import("@/components/RunetypesSelector"),
    CarverInscriptions: () => import("@/components/CarverInscriptions"),
    InscriptionObject: () => import("@/components/InscriptionObject"),
    TextsComponent: () => import("./TextsComponent"),
    ReferencesComponent: () => import("@/components/ReferencesComponent")
    //TransNormFragment: () => import("./TransNormFragment.vue"),
  },
  mixins: [LeaveGuard],
  data() {
    return {
      values: {},
      draft: {},
      draftDetails: {},
      edited: false,
      nilUUID: "00000000-0000-0000-0000-000000000000",
      // keys used to make sure b-form-textareas are updated, see https://github.com/bootstrap-vue/bootstrap-vue/issues/3103
      commentKey: 0,
      publicCommentKey: 0,
      publicCommentEditor: ClassicEditor,
      publicCommentEditorConfig: {
        placeholder: this.$t("components.baseDetail.publicCommentPlaceHolder"),
        toolbar: [
          "heading",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList"
        ]
      },
      modals: {
        visible: {
          publishDraft: false,
          deleteEntity: false,
          deleteDraft: false,
          unsavedChanges: false
        },
        data: {
          deleteEntityName: this.entityName
        }
      },
      localeSection: "pages.inscriptions",
      modernLanguages: [],
      runicLanguages: [],
      periods: [],
      physicalObject: {},
      queryValues: /* GraphQL */ `
        ornamental
        periodCertainty
        period {
          id
        }
        runetypeDescription
        runetypeDescriptionLang {
          id: language
        }
        runeTypes {
          id
        }
        readings {
          reference
          text
          teiText
          sources {
            id
          }
        }
        interpretations {
          readingReference
          text
          teiText
          language {
            id: language
          }
          sources {
            id
          }
        }
        translations {
          readingReference
          text
          teiText
          language {
            id: language
          }
          sources {
            id
          }
        }
        signumInscriptions {
          canonical
          signum {
            id
            signum1 {
              signum1
            }
            signum2
          }
        }
        carverInscriptions {
          carver {
            id
          }
          certainty
          notes
          lang {
            id: language
          }
          attribution
          sources {
            id
          }
        }
        physicalObject {
          id
          artefact
          standardSigna {
            signum1
            signum2
          }
        }
        references {
          reference
          uris {
            id
          }
        }
      `,
      wasValidated: false
    };
  },
  computed: {
    entityName() {
      return this.values.signumInscriptions
        ? this.displayName(this.values.signumInscriptions)
        : "";
    },
    uuid() {
      return this.$route.params.id;
    },
    title() {
      return (
        this.$t(this.localeSection + ".detailHeader") +
        (this.values.signumInscriptions
          ? ": " + this.displayName(this.values.signumInscriptions)
          : "")
      );
    },
    comment() {
      return this.draftDetails && this.draftDetails.comment
        ? this.draftDetails.comment
        : "";
    },
    publicComment() {
      return this.draftDetails && this.draftDetails.publicComment
        ? this.draftDetails.publicComment
        : "";
    },
    isDraft() {
      return this.draftDetails != null && this.draftDetails.draftId != null;
    },
    isNew() {
      return this.$route.params.id == this.nilUUID;
    },
    userName() {
      return this.$root.user !== undefined ? this.$root.user.username : null;
    },
    leaveGuardModalActive() {
      return this.leaveGuard.modalActive;
    }
  },
  watch: {
    edited(newEdited) {
      if (this.leaveGuard) {
        this.leaveGuard.hasChanged = newEdited;
      }
    },
    leaveGuardModalActive(modalActive) {
      if (modalActive) {
        this.showModal("unsavedChanges");
        // = this.leaveGuard.modalActive;
      }
    }
  },
  methods: {
    deleteEntity() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteInscription($id: ID) {
              deleteInscription(id: $id)
            }
          `,
          // Parameters
          variables: { id: this.$route.params.id }
        })
        .then(() => {
          this.makeToast({
            message: this.$t("general.deleted"),
            variant: "success"
          });
          this.$router.push({
            name: this.$route.name,
            params: {
              id: this.nilUUID
            }
          });
          this.$apollo.queries.values.setVariables({ uuid: this.nilUUID });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.makeToast({
            message: this.$t("general.error"),
            variant: "danger"
          });
        });
    },
    deleteDraft() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteInscriptionDraft($id: ID) {
              deleteInscriptionDraft(id: $id)
            }
          `,
          // Parameters
          variables: { id: this.draftDetails.draftId }
        })
        .then(() => {
          this.makeToast({
            message: this.$t("general.deleted"),
            variant: "success"
          });
          if (this.draftDetails.originalId == this.draftDetails.draftId) {
            this.$router.push({
              name: this.$route.name,
              params: {
                id: this.nilUUID
              }
            });
            this.$apollo.queries.values.setVariables({ uuid: this.nilUUID });
          } else {
            this.$apollo.queries.values.refetch();
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.makeToast({
            message: this.$t("general.error"),
            variant: "danger"
          });
        });
    },
    publish() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation publishInscription($id: ID) {
              publishInscription(id: $id)
            }
          `,
          // Parameters
          variables: { id: this.draftDetails.originalId }
        })
        .then(() => {
          this.makeToast({
            message: this.$t("general.published"),
            variant: "success"
          });
          this.$apollo.queries.values.refetch();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.makeToast({
            message: this.$t("general.error"),
            variant: "danger"
          });
        });
    },
    save() {
      if (this.validateForm()) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateInscription($input: InscriptionInput) {
                updateInscription(input: $input) {
                  id
                }
              }
            `,
            variables: {
              input: {
                id: this.$route.params.id,
                ...this.draft,
                comment: this.comment,
                publicComment: this.publicComment
              }
            }
          })
          .then(data => {
            this.setEdited(false);
            this.makeToast({
              message: this.$t("general.saved"),
              variant: "success"
            });
            if (this.isNew) {
              this.$router.push({
                name: this.$route.name,
                params: {
                  id: data.data[Object.keys(data.data)[0]].id
                }
              });
              this.$apollo.queries.values.setVariables({
                uuid: data.data[Object.keys(data.data)[0]].id
              });
            } else {
              this.$apollo.queries.values.refetch();
            }
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error(
              "Could not save. Reason from server: " + error.message
            );
            this.makeToast({
              message: this.$t("general.error"),
              variant: "danger"
            });
          });
      } else {
        this.makeToast({
          message:
            this.$t("general.notSaved") +
            (this.draft["signumInscriptions"] == null ||
            this.draft["signumInscriptions"].length === 0
              ? "\n" + this.$t("pages.inscriptions.signumInscriptionsError")
              : "") +
            (this.draft["physicalObject"] == null ||
            this.draft["physicalObject"].id == null
              ? "\n" + this.$t("pages.inscriptions.physicalObjectError")
              : "") +
            (this.draft["period"] == null || this.draft["period"].id == null
              ? "\n" + this.$t("pages.inscriptions.periodError")
              : ""),
          variant: "danger"
        });
      }
    },
    changed() {
      this.wasValidated = false;
      // if not yet draft and first edit, add time stamp to comment
      if (!this.isDraft && !this.edited) {
        this.draftDetails.comment =
          new Date().toLocaleString(this.$t("general.locale.swedish")) +
          (this.userName ? " (" + this.userName + ")" : "") +
          "\n" +
          this.$t("components.baseDetail.newCommentHere") +
          "\n\n" +
          this.comment;
      }
      // make sure b-form-textarea is updated, see https://github.com/bootstrap-vue/bootstrap-vue/issues/3103
      this.commentKey++;

      this.setEdited(true);
    },
    setEdited(newEdited) {
      this.edited = newEdited;
      this.leaveGuard.hasChanged = true;
    },
    reset() {
      this.values = {};
      this.draft = {};
      this.setEdited(false);

      this.$apollo.queries.values.refetch();
    },
    makeToast({ title, message, append, variant, toaster }) {
      this.$bvToast.toast(message, {
        title: title || this.$t("general.toastTitle"),
        solid: true,
        autoHideDelay: 5000,
        appendToast: append,
        variant,
        toaster: toaster || "b-toaster-top-center"
      });
    },
    showModal(type) {
      this.modals.visible[type] = true;
    },
    scrubProperties(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          if (object[key] != null && typeof object[key] == "object") {
            this.scrubProperties(object[key]);
          } else {
            if (key[0] == "_") {
              delete object[key];
            }
          }
        }
      }
    },
    displayName(signumInscriptions) {
      let name = "",
        extra = [];
      signumInscriptions.forEach(si => {
        si.canonical
          ? (name = si.signum.signum1.signum1 + " " + si.signum.signum2)
          : extra.push(si.signum.signum1.signum1 + " " + si.signum.signum2);
      });
      return extra.length > 0
        ? name +
            " (" +
            extra.sort((a, b) => a.localeCompare(b, "sv")).join(", ") +
            ")"
        : name;
    },
    periodLabel(id) {
      let period = this.periods.find(x => x.id == id);
      return (
        period.period +
        " (" +
        period.sv +
        ", " +
        (period.tpq ? period.tpq : "") +
        "–" +
        (period.taq ? period.taq : "") +
        ")"
      );
    },
    addRunetypeDescription() {
      this.draft.runetypeDescription = "";
      this.draft.runetypeDescriptionLang = { id: "sv-se" };
      this.$emit("change");
    },
    validateForm() {
      // Since only `signumInscriptions`, `period` and `physicalObject` are required, and we have no other restrictions,
      // this check is very simple.
      // If more fields will be required in the future, this will have to be changed into a more
      // complex check.
      // See validateForm() in BaseDetailSimple/detail/index.vue for code that does this.
      let formIsValid =
        this.draft["signumInscriptions"] != null &&
        this.draft["signumInscriptions"].length > 0 &&
        this.draft["period"] != null &&
        this.draft["period"].id != null &&
        this.draft["physicalObject"] != null &&
        this.draft["physicalObject"].id != null;

      this.wasValidated = true;
      return formIsValid;
    }
  },
  metaInfo() {
    return {
      title: this.title
    };
  },
  apollo: {
    values() {
      return {
        query: gql`
          query inscription($uuid: ID) {
            values: inscription(id: $uuid) {
              ${this.queryValues}
            }
            draft: inscriptionDraft(id: $uuid) {
              ${this.queryValues}
            }
            draftDetails: draft(originalId: $uuid) {
              originalId
              draftId
              comment
              publicComment
            }
            modernLanguages {
              id: language
              sv
            }
            runicLanguages {
              id: language
              sv
            }
            periods {
              id
              period
              sv
              tpq
              taq
            }
          }
        `,
        variables: { uuid: this.uuid },
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }
          var _this = this;

          this.values = result.data.values || {};

          // Make draft a copy of the current entity if no draft exists
          this.draft =
            result.data.draft || JSON.parse(JSON.stringify(this.values));
          // Get rid of __typename field and all fields starting with _
          // (See warning at https://akryum.github.io/vue-apollo/guide/apollo/mutations.html)
          this.scrubProperties(this.draft);

          this.draftDetails = result.data.draftDetails || {
            comment: "",
            publicComment: ""
          };

          // Make sure b-form-textareas are updated, see https://github.com/bootstrap-vue/bootstrap-vue/issues/3103
          this.commentKey++;
          this.publicCommentKey++;

          this.modernLanguages = result.data.modernLanguages.sort((a, b) =>
            a.sv.localeCompare(b.sv, "sv")
          );

          this.runicLanguages = result.data.runicLanguages.sort((a, b) =>
            a.sv.localeCompare(b.sv, "sv")
          );

          this.periods = result.data.periods.sort((a, b) => {
            if (!a.taq && !a.tpq) return 1;
            if (!b.taq && !b.tpq) return -1;
            if (a.taq && !b.taq) return -1;
            if (!a.taq && b.taq) return 1;
            if (a.tpq && !b.tpq) return 1;
            if (!a.tpq && b.tpq) return -1;
            if (a.tpq - b.tpq < 0) return -1;
            return a.taq - b.taq;
          });

          if (this.draft.physicalObject) {
            this.physicalObject = JSON.parse(
              JSON.stringify(this.draft.physicalObject)
            );
            delete this.draft.physicalObject.artefact;
            delete this.draft.physicalObject.standardSigna;
          }

          this.draft.signumInscriptions.forEach(si => {
            delete si.signum.signum1;
            delete si.signum.signum2;
          });

          // Some sanity for new inscriptions (nilUUID)
          if (this.uuid == this.nilUUID) {
            this.draft.period = { id: null };
            this.draft.physicalObject = { id: null };
          }
        }
      };
    }
  }
};
</script>
